import React from 'react'
import PropTypes from 'prop-types'

import './divider.scss'

const Divider = ({ children }) => {
  return (
    <h2 className="heading-divider"><span>{children}</span></h2>
  )
}

Divider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Divider
