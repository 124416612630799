import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { Button } from '@ebth/design-system';
import Divider from './divider';

import './indexTabs.scss';
import HeroImage from '../images/HS-Hero.jpg';
import ConsignmentHero from '../images/Consignment-Hero-2.png';

import rug from '../images/rug.jpg';
import mirror from '../images/mirror.jpg';
import pot from '../images/pot.jpg';
import van from '../images/van.jpg';
import art from '../images/art.jpg';
import albers from '../images/albers.jpg';
import statue from '../images/statue.jpg';
import ring from '../images/ring.jpg';
import chanel from '../images/chanel.jpg';
import silver from '../images/silver.jpg';

import imgGroup32 from '../images/Group-3-2.png';
import imgFill1 from '../images/Fill-1.png';
import imgGroup3 from '../images/Group-3.png';
import imgTelephone from '../images/Telephone.png';
import imgServices from '../images/Services.png';
import imgMoney from '../images/Money.png';

function useKeyPress(targetKey) {
  const [keyPressed, setKeyPressed] = useState(false);

  // If pressed key is our target key then set to true
  function downHandler({ key }) {
    if (key === targetKey) {
      if (document.getElementById('tab0') === document.activeElement) {
        document.getElementById('tab1').focus();
      } else if (document.getElementById('tab1') === document.activeElement) {
        document.getElementById('tab0').focus();
      }
    }
  }

  // If released key is our target key then set to false
  const upHandler = ({ key }) => {
    if (key === targetKey) {
      setKeyPressed(false);
    }
  };

  // Add event listeners
  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }); // Empty array ensures that effect is only run on mount and unmount

  return keyPressed;
}

function IndexTabs(props) {
  const [index, setIndex] = useState(props.defaultToConsignment ? 1 : 0);
  const leftPress = useKeyPress('ArrowLeft'); // eslint-disable-line no-unused-vars
  const rightPress = useKeyPress('ArrowRight'); // eslint-disable-line no-unused-vars

  const handleChange = (event, value) => {
    event.preventDefault();
    setIndex(value);
  };

  const handleChangeIndex = (i) => {
    setIndex(i);
  };

  const renderTabButton = (text, indexId) => {
    const style = index === indexId ? 'primary' : 'secondary';
    const active = index === indexId;

    return (
      <Button
        buttonDisplay="block"
        buttonSize="lg"
        buttonStyle={style}
        id={`tab${indexId}`}
        aria-controls={`panel${indexId}`}
        aria-selected={active ? 'true' : 'false'}
        tabIndex={active ? null : '-1'}
        role="tab"
        onClick={(e) => handleChange(e, indexId)}
      >
        {text}
      </Button>
    );
  };

  const renderItem = (item) => {
    return (
      <div className="item-tile" key={item.name}>
        <img src={item.imgUrl} alt={item.name} className="item-tile__img" />
        <h4 className="item-tile__bid">Final Bid: ${item.bid}</h4>
        <div>{item.name}</div>
      </div>
    );
  };

  const serviceItems = [
    {
      name: 'Handwoven Caucasian Dragon Soumak Wool Area\xa0Rug',
      bid: '853',
      imgUrl: rug,
    },
    {
      name: 'Antique English Georgian Style\xa0Mirror',
      bid: '700',
      imgUrl: mirror,
    },
    {
      name: 'French Le Creuset Red Enameled Cast Iron\xa0Bakeware',
      bid: '277',
      imgUrl: pot,
    },
    {
      name: '2013 Toyota Sienna LE\xa0Minivan',
      bid: '13,825',
      imgUrl: van,
    },
    {
      name: 'Robert Fabe Watercolor\xa0Painting',
      bid: '559',
      imgUrl: art,
    },
  ];

  const consignmentItems = [
    {
      name: 'Joseph Albers Oil Painting “Study For A Homage To The\xa0Square”',
      bid: '161,999',
      imgUrl: albers,
    },
    {
      name: 'Finely Cast Sino-Tibetan Gautama\xa0Buddha',
      bid: '11,700',
      imgUrl: statue,
    },
    {
      name: '18K White Gold 3.19CTW Diamond\xa0Ring',
      bid: '17,250',
      imgUrl: ring,
    },
    {
      name: 'Chanel Classic Orange Alligator\xa0Bag',
      bid: '7,866',
      imgUrl: chanel,
    },
    {
      name: 'Sterling Silver Reed & Barton\xa0Flatware',
      bid: '5,050',
      imgUrl: silver,
    },
  ];
  return (
    <div>
      <SwipeableViews
        index={index}
        onChangeIndex={handleChangeIndex}
        enableMouseEvents
      >
        <div>
          <img className="hero-image" src={HeroImage} alt="EBTH Helps You Consign Smarter" />
        </div>
        <div>
          <img className="hero-image" src={ConsignmentHero} alt="EBTH Helps You Consign Smarter" />
        </div>
      </SwipeableViews>

      <div className="sell-layout u-pb4">
        <div className="btn-group" role="tablist" aria-label="Service Type">
          {renderTabButton('Home Services', 0)}
          {renderTabButton('Consignment Partnership', 1)}
        </div>

        <SwipeableViews
          index={index}
          onChangeIndex={handleChangeIndex}
          enableMouseEvents
        >
          <div role="tabpanel" aria-labelledby="tab0" id="panel0" tabIndex="0" hidden={index === 1 ? '' : null}>
            <p className="audience-intro">From the removal of a few personal things to the contents of an entire home, our full-service model makes home transitions & estate&nbsp;sales&nbsp;simpler.</p>

            <div className="why-sell">
              <div className="why-sell__item">
                <img src={imgTelephone} alt="" className="why-sell__img" />
                <h4 className="why-sell__heading">Free Consultation</h4>
                <p className="u-m0">Get started online or <br />call (888) 965-8672</p>
              </div>
              <div className="why-sell__item">
                <img src={imgServices} alt="" className="why-sell__img" />
                <h4 className="why-sell__heading">Personalized Service</h4>
                <p className="u-m0">Full service solutions from select pick-up to complete home clean&nbsp;outs.</p>
              </div>
              <div className="why-sell__item">
                <img src={imgMoney} alt="" className="why-sell__img" />
                <h4 className="why-sell__heading">Better Outcomes</h4>
                <p className="u-m0">We handle shipping &<br /> send you the&nbsp;proceeds.</p>
              </div>
            </div>

            <Divider>Things of Value</Divider>

            <div className="l-flex-rows-5">
              {serviceItems.map((item) => renderItem(item))}
            </div>

          </div>
          <div role="tabpanel" aria-labelledby="tab1" id="panel1" tabIndex="0" hidden={index === 0 ? '' : null}>
            <p className="audience-intro">Finding objects that deserve celebration takes time, talent and expertise. We help you stay focused on sourcing items & delivering strong bottom-line&nbsp;performance.</p>

            <div className="why-sell">
              <div className="why-sell__item">
                <img src={imgGroup32} alt="" className="why-sell__img" />
                <h4 className="why-sell__heading">Global Audience</h4>
                <p className="u-m0">2 million+ registered users from across&nbsp;the&nbsp;world</p>
              </div>
              <div className="why-sell__item">
                <img src={imgFill1} alt="" className="why-sell__img" />
                <h4 className="why-sell__heading">Quick Turn-around</h4>
                <p className="u-m0">Fast and full-service; authentication, photography &amp; fulfillment</p>
              </div>
              <div className="why-sell__item">
                <img src={imgGroup3} alt="" className="why-sell__img" />
                <h4 className="why-sell__heading">Top Market Prices</h4>
                <p className="u-m0">Our unique platform helps generate&nbsp;more&nbsp;revenue.</p>
              </div>
            </div>

            <Divider>What We Sell</Divider>

            <div className="l-flex-rows-5">
              {consignmentItems.map((item) => renderItem(item))}
            </div>
          </div>
        </SwipeableViews>
      </div>
    </div>
  );
}

IndexTabs.propTypes = {
  defaultToConsignment: PropTypes.bool,
};

IndexTabs.defaultProps = {
  defaultToConsignment: false,
};

export default IndexTabs;
