import React, { useContext } from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import IndexTabs from '../components/indexTabs';

import Divider from '../components/divider';
import { CookieContext } from '../context/CookieProvider';

import './index.scss';

import imgSidBurns from '../images/sid_burns-portrait.jpg';
import imgGetReady from '../images/ONBOARDING_getreadytobid_01.png';

const IndexPage = () => {
  const context = useContext(CookieContext);
  return (
    <Layout pageName="Seller Landing Page">
      <SEO title="Home" />
      {/*
        Use query param `t` to handle choosing between consignment vs home-services. Defaults to home sercies
        ex: sell.ebth.com/?t=consignment
      */}
      <IndexTabs defaultToConsignment={context && context.t === 'consignment'} />
      <div className="sell-layout">
        <img
          src={imgSidBurns}
          className="pull-quote__img"
          width="759"
          alt="SidBurns"
          title="SidBurns"
          sizes="(max-width: 759px) 100vw, 759px"
        />
        <aside className="pull-quote clearfix">
          “The best part [about selling with EBTH] was walking into the house for
          the first time after the auction. It was clear and clean. For the first
          time I wasn’t being overwhelmed by the question ‘What am I going to do
          with all this stuff?’ I could move on.”
          <cite className="pull-quote__attribution">
            &mdash;<strong>Shelby Burns</strong>, granddaughter
          </cite>
        </aside>

        <Divider>THE EBTH DIFFERENCE</Divider>

        <div className="l-flex-rows-2">
          <div>
            <ul className="checklist">
              <li>End-to-end service so you never lift a finger</li>
              <li>More revenue than traditional alternatives</li>
              <li>Over 1.8 million bids per month from users across the globe</li>
              <li>Quick turn-around &amp; full-service; authentication, photography &amp; fulfillment</li>
            </ul>
          </div>
          <div>
            <img
              src={imgGetReady}
              className="hs-image-widget"
              width="1154"
              alt="EBTH Mobile website"
              title="EBTH Mobile website"
              sizes="(max-width: 1154px) 100vw, 1154px"
            />
          </div>
        </div>

        <Divider>HOW IT WORKS</Divider>
        <p className="u-text-center">
          Stuff can be complicated. Let us take care of everything.
        </p>

        <div className="l-flex-rows-2 u-mb6">
          <div>
            <h3>YOU</h3>
            <ul>
              <li>Complete the form</li>
              <li>Hear from one of our team members</li>
              <li>Get paid</li>
            </ul>
          </div>
          <div>
            <h3>US</h3>
            <ul>
              <li>Reach out to you to discuss your goals</li>
              <li>Finalize the contract</li>
              <li>Sort and remove your items</li>
              <li>Authenticate your items</li>
              <li>Photograph items</li>
              <li>
                Launch the items in an online auction to our global audience for
                5-7 days
              </li>
              <li>
                Sale closes and we manage all payment, pick-up and shipping
                fulfillment for the items
              </li>
              <li>Send you payment within 30 days</li>
            </ul>
          </div>
        </div>

        <div className="u-text-center u-mb4">
          <Link to="/get-started/" className="btn btn--primary btn--xl">
            Start Selling
          </Link>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
